import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout';
import styled from "styled-components";
import PostCardMinimal from "../components/post-card-minimal/post-card-minimal";
import BlogMeta from "../components/blog-meta/blog-meta";
import {CounterFallbacks} from "../interact";


const Presenter = styled.div`
  width: 800px;
  height: 800px;
  position: relative;

  svg {
    margin-right: 5px;
    transform: translateY(5px);
  }

  .pix-img.item-meta {
    background-color: white;
    padding-top: 6.5px; // 20px default - 13.5px tag top
  }
  
  .preview-meta {
    bottom: 0 !important;
    padding: 20px;
    width: 800px;
    font-size: 45px !important;


    div.top-meta {
      margin-bottom: 15px;
      display: flex;
      flex-wrap: wrap-reverse;

        span > span {
          font-size: 45px !important;
          margin-right: .3em;
          margin-top: .3em;
          padding-right: .3em;
          padding-left: .3em;
        }
    }

    div.bottom-meta {
      grid-template-columns: repeat(4, 25%) !important;
      justify-content: center;
      align-content: center;
    }
  }

  &:hover {
    .post-title, .post-preview, .post-description {
      opacity: unset !important;
    }
  }

  .post-card {
    height: 800px;
  }

  .svg-wrapper, .post-card {
    border-radius: unset;
  }

  h1.post-title {
    color: white;
    font-size: 80px;
    margin: 15px;

    &.pix-img {
      color: white;
      text-shadow: 0 0 20px black;
    }
  }

  .svg-wrapper {
    background-size: 800px;
  }
`


const BlogPreviewTemplate = ({data, pageContext}: { data: any; pageContext: any }) => {
    const node = data.markdownRemark.frontmatter;
    const claps = node.claps
    const views = node.views
    const n_fallbacks: CounterFallbacks = {views: views ?? 0, claps: claps ?? 0};
    // const is_svg = node.cover.publicURL.endsWith('.svg')
    return (
        <Layout do_footy={false} do_sticky={false}>
            <Presenter>
                <PostCardMinimal
                    title={node.title}
                    image={node.cover}
                    color={`#${node.color}`}
                    doDarken={true}
                />
                <BlogMeta
                    max_n_tags={3}
                    slug={pageContext.slug}
                    tags={node.tags}
                    date={node.date}
                    // className={`item-meta preview-meta ${is_svg ? 'svg-img' : 'pix-img'}`}
                    className={`item-meta preview-meta`}
                    isVisit={false}
                    can_interact={false}
                    audio={node.audio}
                    n_fallbacks={n_fallbacks}
                />
            </Presenter>
        </Layout>
    );
};

export default BlogPreviewTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        color
        title
        audio
        claps
        views
        tags
        cover {
          publicURL
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;
